<loading-overlay *ngIf="loading" [loadingMessage]="loadingMessage"></loading-overlay>
<table *ngIf="!loading && permitSpeciesControl" class="table table-bordered" [formGroup]="form">
    <thead>
        <tr class="detail-grid-header">
            <th colspan="12">
                <a class="add-item-link" (click)="addPermitSpecies()">
                    <i class="fa fa-plus-circle" title="Add species"></i>
                </a>
                <span [ngClass]="{'required-text': required && permitSpeciesControl.length == 0}">Species Information</span>
            </th>
        </tr>
        <tr class="detail-grid-header">
            <th class="icon-cell"></th>
            <th>Species</th>
            <th>{{translationService.translate('Line')}}(s)</th>
            <th>Animals On Permit</th>
            <th>Highest Severity Allowed</th>
            <th>Non-Recovery Allowed</th>
            <th>Reuse Allowed</th>
            <th>Origin(s)</th>
            <th>End State Animals</th>
            <th>Live Animals</th>
            <th>Approx. Animals Assigned</th>
            <th>Approx. Animals Remaining</th>
        </tr>
    </thead>
    <tbody formArrayName="PermitSpecies">
        <tr *ngFor="let permitSpecies of permit?.PermitSpecies; let i = index" [formGroupName]="i">
            <td class="icon-cell">
                <a (click)="deletePermitSpecies(permitSpecies, i)">
                    <i class="fa fa-remove remove-item" title="Delete species"></i>
                </a>
            </td>
            <td>
                <active-vocab-select data-automation-id="permit-species-dropdown"
                    formControlName="SpeciesKey"
                    [vocabChoices]="species"
                    [keyFormatter]="permitSpeciesKeyFormatter"
                    [optionFormatter]="permitSpeciesFormatter"
                    [displayName]="'Species'"
                    [required]="true">
                </active-vocab-select>
            </td>
            <td>
                <line-multiselect formControlName="PermitSpeciesLine" [isOnlyActiveSearch]="true"></line-multiselect>
            </td>
            <td>
                <input type="number" class="form-control input-medium" placeholder="Enter number of animals" formControlName="AnimalsOnPermit">
            </td>
            <td>
                <active-vocab-select *ngIf="severities.length" data-automation-id="permit-severity-dropdown"
                    formControlName="SeverityKey"
                    [vocabChoices]="severities"
                    [keyFormatter]="severityKeyFormatter"
                    [optionFormatter]="severityFormatter"
                    [displayName]="'Highest Severity Allowed'"
                    [nullable]="true">
                </active-vocab-select>
            </td>
            <td class="text-center">
                <input type="checkbox" formControlName="NoRecoveryAllowed" name="no_recovery_allowed" id="no_recovery_allowed">
            </td>
            <td class="text-center">
                <input type="checkbox" formControlName="ReuseAllowed" name="reuse_allowed" id="reuse_allowed">
            </td>
            <td>
                <active-vocab-typeahead-multi-select formControlName="PermitSpeciesOrigin" [vocabTableName]="'cv_MaterialOrigin'" [includeInactive]="false"></active-vocab-typeahead-multi-select>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
</table>
